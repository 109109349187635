<script setup>
    import Layout from "@/layouts/main.vue";
    import PageHeader from "@/components/page-header";  
    import "@suadelabs/vue3-multiselect/dist/vue3-multiselect.css";
    import { required, helpers, maxValue } from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import { computed, ref, onMounted } from 'vue';
    import { useStore } from 'vuex';

    const state = ref({
        camera_id: null,
        location: '',
        lanes: null,
        camera_type_id: '',
        lgu_id: '',
        is_enabled: false
    });

    const rules = {
        camera_id: {
            required: helpers.withMessage('Camera ID is required', required),
        },
        location: {
            required: helpers.withMessage("Camera Location Name is required", required),
        },
        lanes: {
            required: helpers.withMessage("Camera Lanes is required", required),
            maxValue: helpers.withMessage("Camera Lanes not greater than 32767", maxValue(32767))
        },
        camera_type_id: {
            required: helpers.withMessage("Camera Type is required", required),
        },
        lgu_id: {
            required: helpers.withMessage("LGU is required", required),
        }
    }

    const store = useStore();
    const v$ = useVuelidate(rules, state);

    const loading = computed(() => store.state.globals.loading);
    const title = ref("Camera Dashboard");
    const items = ref([
        {
            text: "Home",
            href: "/",
        },
        {
            text: "Settings",
            href: "/camera/dashboard/list",
            active: true
        },
        {
            text: "Add New Camera",
            active: true,
        }
    ]);

    const lguOptions = computed(() => store ? store.state.lgu.dropdownLGUs : []);
    const cameraTypeOptions = computed(() => store ? store.state.camera.dropdownCameraTypes: [] );
    const submitted = ref(false);
    const notification = computed(() => store ? store.state.notification : null)

    function createCamera() {
        submitted.value = true
        v$.value.$touch();

        if (v$.value.$invalid) return;
        store.dispatch('camera/createCamera', state.value);
    }

    function getAllLGUs() {
        store.dispatch('lgu/getDropdownLGUs');
    }

    function getAllCameraTypes() {
        store.dispatch('camera/getDropdownCameraTypes');
    }

    onMounted(() => {
        getAllLGUs();
        getAllCameraTypes();
    })
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col lg-12">
            <b-overlay :show="loading">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title mb-0">Add New Camera</h4>
                </div>
                <div class="card-body">
                    <div v-if="notification && notification.messages.length > 0">
                        <b-alert v-for="(message, index) in notification.messages" :key="index"
                            show variant="danger" class="fade show" role="alert">
                            {{ message }}
                        </b-alert>
                    </div>

                    <form @submit.prevent="createCamera" class="mx-2">
                        <div class="row">
                            <p class="fs-6 text text-decoration-underline">Camera Information</p>
                             <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="camera-location-id" class="form-label required">Camera ID</label> 
                                    <input 
                                        :class="{ 'is-invalid': submitted && v$.camera_id.$error }"
                                        v-model="state.camera_id" v-maska="'########'" class="form-control" type="text" placeholder="Enter camera location ID..." id="camera-location-id" />
                                      <div
                                        v-for="item of v$.camera_id.$errors"
                                        :key="item.$uid"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="camera-location-name" class="form-label required">Camera Location Name</label> 
                                    <input 
                                        :class="{ 'is-invalid': submitted && v$.location.$error }"
                                        v-model="state.location" class="form-control" type="text" placeholder="Enter camera location name..." id="camera-location-name" />
                                      <div
                                        v-for="item of v$.location.$errors"
                                        :key="item.$uid"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="camera-lane" class="form-label required">Camera Lanes</label> 
                                    <input 
                                        :class="{ 'is-invalid': submitted && v$.lanes.$error }"
                                        v-model="state.lanes" v-maska="'#####'" class="form-control" placeholder="Enter camera lane..." id="camera-lane" />
                                    <div
                                        v-for="item of v$.lanes.$errors"
                                        :key="item.$uid"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="lguInput" class="form-label required">LGU</label>
                                    <select 
                                        :class="{ 'is-invalid': submitted && v$.lgu_id.$error }"
                                        class="form-select" v-model="state.lgu_id">
                                        <option value="" disabled selected>Select LGU</option>
                                        <option :value="lgu.id"  v-for="lgu in lguOptions" :key="lgu.id">
                                            {{ lgu.name }}
                                        </option>
                                    </select>
                                     <div
                                        v-for="item of v$.lgu_id.$errors"
                                        :key="item.$uid"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="cameraTypeInput" class="form-label">Camera Types</label>
                                    <select 
                                        :class="{ 'is-invalid': submitted && v$.camera_type_id.$error }"
                                        class="form-select" v-model="state.camera_type_id">
                                        <option value="" disabled selected>Select Camera Type</option>
                                        <option v-for="(type) in cameraTypeOptions" :value="type.id" :key="type.id">
                                            {{ type.name }}
                                        </option>
                                    </select>
                                    <div
                                        v-for="item of v$.camera_type_id.$errors"
                                        :key="item.$uid"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="item.$message">{{ item.$message }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label for="cameraTypeInput" class="form-label">Camera Status</label>
                                    <div class="form-check form-switch form-switch-lg" dir="ltr">
                                        <input type="checkbox" v-model="state.is_enabled" class="form-check-input" id="camera-status" checked="">
                                        <label class="form-check-label" for="customSwitchsizelg">{{ state.is_enabled ? 'Enabled' : 'Disabled' }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="text-end">
                                     <router-link to="/camera/dashboard/list" type="submit" class="btn btn-danger mr-3">
                                        <i class="ri-close-fill fs-4 mr-1 valign-middle"></i>
                                        Cancel
                                     </router-link>
                                    <button type="submit" class="btn btn-success">
                                        <i class="ri-save-2-fill fs-4 mr-1 valign-middle"></i>
                                        Add Camera
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </b-overlay>
        </div>
    </div>
  </Layout>
</template>